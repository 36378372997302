import { orderBy } from 'lodash';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Util from '@/support/utility';
import { ISortSpecifier } from '@/interfaces';

interface IColumnDef {
    name: string;
    title: string;
    sortField: string;
    titleClass: string;
    dataClass: string;
    activeSort: boolean;
    ascending: boolean;
    hide: boolean;
}

@Component({
    filters: {
        capitalize(str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },

        formatDate(value: string) {
            return Util.formatDate(value);
        }
    }
})
export default class VueGridComponent extends Vue {
    options = {
    };

    @Prop(<any>{ default: [] })
    data: Array<any>;

    @Prop(<any>{ default: [] })
    columns: Array<IColumnDef>;

    @Prop({ default: null })
    filterKey: string;

    @Prop(<any>{ default: () => { return []; } })
    sort: ISortSpecifier[];

    @Prop(<any>{ default: false })
    selectMode: boolean;

    @Prop(<any>{ default: false })
    searchMode: boolean;

    @Prop(<any>{ default: () => { return []; } })
    selectedTo: Array<any>;

    get visibleColumns() {
        let count = this.refreshCount; // trigger refresh
        return this.columns.filter(item => !item.hide);
    }

    get dataCount() {
        return this.filteredData.length;
    }

    private select = {
        mode: false,
        count: 0,
        checked: false,
    };

    private loaded = false;
    private rows: Array<any> = [];
    private fields: IColumnDef[] = [];
    private refreshCount = 0;
    private sortOrders: ISortSpecifier[] = [];

    @Watch('data', { immediate: true })
    onDataChanged(val: string, oldVal: string) {
        this.initializeData();
    }

    @Watch('columns', { immediate: true })
    onColumnsChanged(val: string, oldVal: string) {
        this.initializeColumns();
    }

    @Watch('selectMode', { immediate: true })
    onSelectModeChanged(val: string, oldVal: string) {
        this.select.mode = this.selectMode;
        this.initializeData();
        this.refresh();
    }

    @Watch('sort', { immediate: true })
    onSortChanged(val: string, oldVal: string) {
        this.initializeSort();
        this.refresh();
    }

    get filteredData() {
        if (!this.loaded) return [];

        let count = this.refreshCount; // trigger refresh
        let filterKey = this.filterKey && this.filterKey.toLowerCase();
        let data = this.rows;

        if (filterKey) {
            data = data.filter(row => {
                return Object.keys(row).some(key => {
                    return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
                });
            });
        }

        if (this.sortOrders.length) {

            let sortKeys = [];
            let directions = [];

            for (let idx = 0; idx < this.sortOrders.length; idx++) {
                    let order = this.sortOrders[idx];
                let key = order.sortField || order.name;
                sortKeys.push(key);
                directions.push(order.ascending ? 'asc' : 'desc');
            }
            data = orderBy(data, sortKeys, directions);
        }

        // Un-select non-filtered rows
        this.rows.forEach(row => {
            let originalData = this.data[row.__idx];

            let idx = data.indexOf(row);
            if (idx == -1) { // check if excluded from filtered st
                row.selected = false;
                idx = this.selectedTo.indexOf(originalData);
                if (idx != -1) {
                    this.selectedTo.splice(idx, 1);
                }
            }
        });

        this.$emit('dataChanged', data.length);

        return data;
    }

    created() {
    }

    mounted() {

        this.initializeData();

        this.loaded = true;
        this.initializeSort();
        this.refresh();
    }

    sortBy(column: IColumnDef) {
        if (!this.loaded) return;

        let key = column.name;
        if (!key || key == 'selected') return;

        this.columns.forEach(column => {
            column.activeSort = false;
        });

        column.ascending = !column.ascending;
        column.activeSort = true;

        this.sortOrders.splice(0, this.sortOrders.length);
        this.sortOrders.push({
            name: column.name,
            sortField: column.sortField || column.name,
            ascending: column.ascending,
        });

        this.refresh();
    }

    thenBy(column: IColumnDef) {
        if (!this.loaded) return;

        Debug.log('thenBy', column.name, column.sortField, column);

        if (this.sortOrders.length == 0) {
            this.sortBy(column);
            return;
        }

        column.ascending = !column.ascending;
        column.activeSort = true;

        let idx = this.sortOrders.findIndex(item => item.name == column.name);
        if (idx != -1)
            this.sortOrders.splice(idx, 1);

        this.sortOrders.push({
            name: column.name,
            sortField: column.sortField || column.name,
            ascending: column.ascending,
        });

        Debug.log('thenBy', Util.dumpJson(this.sortOrders));

        this.refresh();
    }

    onSelectAll() {
        let rows = this.filteredData;

        rows.forEach(data => {
            data.selected = this.select.checked;
        });

        this.selectedTo.splice(0, this.selectedTo.length);
        if (this.select.checked) {
            rows.forEach(data => {
                let originalData = this.data[data.__idx];
                this.selectedTo.push(originalData);
            });
        }

        this.refresh();
    }

    onSelected(data: any) {
        let count = 0;
        let rows = this.filteredData;

        rows.forEach(item => {
            if (item.selected)
                count++;
        });

        this.select.count = count;
        this.select.checked = count == rows.length;

        // update selectedTo array
        let originalData = this.data[data.__idx];
        let idx = this.selectedTo.indexOf(originalData);
        if (data.selected && idx == -1) {
            this.selectedTo.push(originalData);
        } else if (!data.selected && idx != -1) {
            this.selectedTo.splice(idx, 1);
        }

        this.refresh();
    }

    refresh() {
        this.refreshCount++;
    }

    initializeData() {
        this.rows = [];
        if (!this.data) return;

        let idx = 0;
        this.data.forEach(data => {
            let newItem = data;
            newItem.__idx = idx++;
            newItem.selected = false;
            this.rows.push(newItem);
        });

        this.selectedTo.splice(0, this.selectedTo.length);
    }

    initializeWithNewData() {
        this.rows = [];
        if (!this.data) return;

        let idx = 0;
        this.data.forEach(data => {
            let newItem = Object.assign({}, data);
            newItem.__idx = idx++;
            newItem.selected = false;
            this.rows.push(newItem);
        });

        this.selectedTo.splice(0, this.selectedTo.length);
    }

    findColumn(key: string) {
        let found = this.columns.find(item => item.name == key);
        return found;
    }

    initializeColumns() {
        if (!this.columns) return;

        this.columns.forEach(column => {
            column.title = column.title || column.name.charAt(0).toUpperCase() + column.name.slice(1);
            column.sortField = column.sortField || column.name;
            column.dataClass = column.dataClass || '';
            column.titleClass = column.titleClass || '';
            column.activeSort = column.activeSort || false;
            column.ascending = column.ascending || false;
            column.hide = column.hide || false;
        });
    }

    initializeSort() {
        this.sortOrders.splice(0, this.sortOrders.length);

        this.sort.forEach(order => {
            this.sortOrders.push(order);

            let column = this.columns.find(item => item.name == order.name);
            if (column) {
                column.activeSort = true;
                column.ascending = order.ascending;
            }
        });
    }
}
